import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import ProductFilter from './components/ProductFilter';

const ProductsPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | PRODUCTS</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/jquery.countdown.js',
      '/js/mixitup.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
      '/js/st-products-custom.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='products' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Products</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- Featured Products --> */}
        <section className='featured-products'>
          <span className='bg-shape'></span>
          <div className='auto-container'>
            <div className='row clearfix'>
              <div className='col-lg-12 col-md-12 col-sm-12 content-side'>
                {/* <!--MixitUp Galery--> */}
                <div className='mixitup-gallery'>
                  {/* <!--Filter--> */}
                  {/* <div
                    id='product-category-selection'
                    className='filters clearfix'
                  ></div>

                  <div id='products-display' className='filter-list row'></div> */}
                  <ProductFilter />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Featured Products --> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default ProductsPage;
