import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const ResidentialIntercomPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | RESIDENTIAL INTERCOM</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='residential-intercom' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/3.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      <h5>1. Excellent User Experience</h5>
                      <p>
                        <strong>Overview:</strong> The SIP-based residential
                        intercom system is designed to provide residents with a
                        seamless and intuitive communication experience.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> High-Quality Audio and Video:
                            Supports high-definition audio and video, allowing
                            residents to see and communicate clearly with
                            visitors.
                          </li>
                          <li>
                            <strong>・</strong> User-Friendly Interface: The
                            interface is easy to navigate, enabling residents of
                            all ages to use the system without difficulty.
                          </li>
                          <li>
                            <strong>・</strong> Mobile and Remote Access:
                            Residents can manage intercom functions from their
                            smartphones or tablets, enhancing convenience and
                            accessibility.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>2. Convenience</h5>
                      <p>
                        <strong>Overview:</strong> The system streamlines
                        communication within the residence and offers added
                        features that enhance daily living.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Two-Way Communication: Enables
                            residents to communicate directly with visitors at
                            the entrance or with other residents within the
                            building.
                          </li>
                          <li>
                            <strong>・</strong> Integration with Smart Home
                            Devices: Can be integrated with smart home systems,
                            allowing for features such as door locking and
                            lighting control.
                          </li>
                          <li>
                            <strong>・</strong> Call Forwarding: Residents can
                            forward calls to their mobile devices, ensuring they
                            never miss a visitor or important call.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>3. Security</h5>
                      <p>
                        <strong>Overview:</strong> The SIP-based intercom
                        solution prioritizes security, providing peace of mind
                        for residents.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Video Monitoring: Allows
                            residents to see who is at the door before granting
                            access, enhancing security against unwanted
                            visitors.
                          </li>
                          <li>
                            <strong>・</strong> Remote Access Control: Residents
                            can grant or deny access to the building remotely,
                            adding an extra layer of security.
                          </li>
                          <li>
                            <strong>・</strong> Integration with Surveillance
                            Systems: Can be integrated with existing security
                            cameras and systems, allowing for comprehensive
                            monitoring of the premises.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>4. Efficient Deployment and Operation</h5>
                      <p>
                        <strong>Overview:</strong> The SIP-based solution is
                        designed for easy deployment, operation, and
                        maintenance, minimizing disruption for residents.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Scalable Architecture: Easily
                            scalable to accommodate additional units or features
                            as needed, making it suitable for various
                            residential sizes and types.
                          </li>
                          <li>
                            <strong>・</strong> Centralized Management:
                            Administrators can manage the system centrally
                            through a web interface, simplifying updates and
                            maintenance.
                          </li>
                          <li>
                            <strong>・</strong> Cost-Effective: Utilizing SIP
                            technology reduces the need for extensive wiring,
                            making installation quicker and more cost-effective.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>5. Extension and Maintenance</h5>
                      <p>
                        <strong>Overview:</strong> The system is designed for
                        long-term use with minimal maintenance needs.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Easy Software Updates: Remote
                            software updates ensure that the system stays
                            current with the latest features and security
                            enhancements.
                          </li>
                          <li>
                            <strong>・</strong> User-Friendly Troubleshooting:
                            Built-in diagnostics and user support tools help
                            residents troubleshoot minor issues without
                            professional assistance.
                          </li>
                          <li>
                            <strong>・</strong> Long-Term Support: Ongoing
                            technical support ensures that residents can get
                            assistance whenever needed, enhancing the overall
                            user experience.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      The SIP-based residential intercom solution combines
                      convenience, security, and a superior user experience to
                      meet the needs of modern residential environments. With
                      its easy deployment, operational efficiency, and strong
                      focus on security, this intercom system enhances
                      communication within the home and provides residents with
                      the tools they need to feel safe and connected. This makes
                      it an ideal choice for both new constructions and
                      retrofitting existing residences.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default ResidentialIntercomPage;
