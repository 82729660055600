// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import WhatsappButton from './WhatsappButton';

const Footer = () => {
  return (
    <>
      <WhatsappButton />
      {/* <!-- Main Footer --> */}
      <footer className='main-footer'>
        <div
          className='bg-image'
          style={{ backgroundImage: 'url(/images/background/8-red.png)' }}
        ></div>

        {/* <!--Widgets Section--> */}
        <div className='widgets-section'>
          <div className='auto-container'>
            <div className='row'>
              {/* <!--Footer Column--> */}
              <div className='footer-column col-xl-4 col-lg-12 col-md-12 col-sm-12'>
                <div className='footer-widget about-widget'>
                  <div className='logo'>
                    <Link to={'/'}>
                      <img src='/images/startech_logo.png' alt='' />
                    </Link>
                  </div>
                  <ul className='social-icon-two'>
                    <li>
                      <a
                        href='https://www.linkedin.com/company/startechdistribution'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.facebook.com/startechdistributionsb/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i className='fab fa-facebook'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!--Footer Column--> */}
              <div className='footer-column col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                <div className='footer-widget'>
                  <h3 className='widget-title'>Explore</h3>
                  <ul className='user-links'>
                    <li>
                      <Link to={'/about'}>About Us</Link>
                    </li>
                    <li>
                      <Link to={'/news'}>News & Events</Link>
                    </li>
                    <li>
                      <Link to={'/portfolio'}>Portfolio</Link>
                    </li>
                    <li>
                      <Link to={'/products'}>Products</Link>
                    </li>
                    <li>
                      <Link to={'/solutions'}>Solutions</Link>
                    </li>
                    <li>
                      <Link to={'/technical-center'}>Technical Center</Link>
                    </li>
                    <li>
                      <Link to={'/career'}>Career</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <!--Footer Column--> */}
              <div className='footer-column col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                <div className='footer-widget contact-widget'>
                  <h3 className='widget-title'>Contact</h3>
                  <div className='widget-content'>
                    <div className='text'>
                      No. 31-2, Petaling Utama Avenue, Jalan PJS 1/50, Taman
                      Petaling Utama, 46150, Petaling Jaya , Selangor. Malaysia.
                    </div>
                    <ul className='contact-info'>
                      <li>
                        <i className='fa fa-envelope'></i>
                        <a
                          className='text-nowrap'
                          href='mailto:enquiry@startechdistribution.com'
                        >
                          enquiry@startechdistribution.com
                        </a>
                        <br />
                      </li>
                      <li>
                        <i className='fa fa-phone-square'></i>
                        <a href='tel:+60377828826'> +603-7782 8826 </a>
                        <br />
                      </li>
                      <li>
                        <i className='fa fa-phone-square'></i>
                        <a
                          href={`https://api.whatsapp.com/send/?phone=60126232077`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {' '}
                          +6012-6232077{' '}
                        </a>
                        <br />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--Footer Bottom--> */}
        <div className='footer-bottom'>
          <div className='auto-container'>
            <div className='inner-container bg-danger'>
              <div className='copyright-text text-light'>
                © 2024 STAR TECH DISTRIBUTION SDN. BHD. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--End Main Footer --> */}
    </>
  );
};

export default Footer;
