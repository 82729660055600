import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const OfficeIntercomPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | OFFICE INTERCOM</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='office-intercom' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/4.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      <h5>1. Connecting Staff’s Stations</h5>
                      <p>
                        <strong>Overview:</strong> The office intercom system
                        enhances communication among staff members by connecting
                        their workstations with phones and intercom devices.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Direct Communication: Enables
                            instant voice communication between different
                            departments or teams, promoting quick information
                            sharing and collaboration.
                          </li>
                          <li>
                            <strong>・</strong> Integration with Existing Phone
                            Systems: Seamlessly integrates with existing VoIP or
                            PBX systems, ensuring that staff can communicate
                            effectively without additional hardware.
                          </li>
                          <li>
                            <strong>・</strong> Intercom Features: Offers
                            features such as call transfer, intercom paging, and
                            group calling, making it easy to connect multiple
                            users at once.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>
                        2. Complete Surveillance for a Safe Working Environment
                      </h5>
                      <p>
                        <strong>Overview:</strong> The intercom system includes
                        surveillance capabilities to enhance security within the
                        office premises.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> HD Video Surveillance: Provides
                            high-definition video feeds from strategically
                            placed cameras throughout the office, allowing for
                            real-time monitoring.
                          </li>
                          <li>
                            <strong>・</strong> Remote Access: Enables
                            authorized personnel to view live camera feeds and
                            recorded footage remotely, ensuring constant
                            oversight.
                          </li>
                          <li>
                            <strong>・</strong> Integration with Security
                            Systems: Can be integrated with alarms and access
                            control systems to enhance overall security
                            measures.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>
                        3. Meeting Room Setup with HD Conferencing Gadgets
                      </h5>
                      <p>
                        <strong>Overview:</strong> The intercom system includes
                        advanced conferencing technology to facilitate efficient
                        meetings.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> HD Video Conferencing: Equipped
                            with high-definition cameras and audio systems to
                            provide clear video and sound for virtual meetings,
                            ensuring an effective communication experience.
                          </li>
                          <li>
                            <strong>・</strong> Easy Room Scheduling: Integrates
                            with calendar systems to allow staff to schedule
                            meetings easily and check room availability.
                          </li>
                          <li>
                            <strong>・</strong> Screen Sharing and Collaboration
                            Tools: Supports screen sharing and includes
                            interactive tools like whiteboards, making remote
                            collaboration seamless and productive.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>
                        4. Safe & Secure Server and Gateway for Confidential
                        Document Storage
                      </h5>
                      <p>
                        <strong>Overview:</strong> The intercom system includes
                        secure servers and gateways designed to protect
                        sensitive information and confidential documents.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Data Encryption: Ensures that
                            all stored and transmitted data is encrypted,
                            safeguarding against unauthorized access and data
                            breaches.
                          </li>
                          <li>
                            <strong>・</strong> Access Control: Implements user
                            authentication and permissions to restrict access to
                            sensitive information, allowing only authorized
                            personnel to view or manage documents.
                          </li>
                          <li>
                            <strong>・</strong> Regular Backups: Features
                            automated backup solutions to protect against data
                            loss, ensuring business continuity and data
                            integrity.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      The Office Intercom service offers a comprehensive
                      solution for enhancing communication, security, and
                      collaboration within the workplace. By connecting staff
                      stations, providing surveillance capabilities,
                      facilitating high-definition meetings, and ensuring the
                      secure storage of confidential documents, this intercom
                      system supports a safe and efficient working environment.
                      This makes it an ideal choice for businesses looking to
                      improve operational efficiency and security.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default OfficeIntercomPage;
