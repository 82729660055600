import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const PagaSystemPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | PAGA SYSTEM</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='paga-system' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/5.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      <p>
                        The Public Address and General Alarm (PAGA) system is a
                        critical communication tool designed to ensure safety
                        and effective internal communication across large
                        properties. KNTECH’s offshore platform PAGA system
                        integrates various communication technologies, enhancing
                        operational efficiency and response capabilities in
                        diverse environments, including schools, hospitals,
                        train stations, commercial buildings, and ports.
                      </p>
                    </div>

                    <div className='my-4'>
                      <h5>Key Features:</h5>
                      <div>
                        <strong>1. Integrated Communication:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> PABX Integration: Seamlessly
                            connects with the Private Automatic Branch Exchange
                            (PABX) phone system, allowing for direct
                            communication across departments and with external
                            contacts.
                          </li>
                          <li>
                            <strong>・</strong> Audio/Video Intercom:
                            Facilitates clear and efficient communication
                            through audio and video intercom systems, enabling
                            real-time interaction in critical situations.
                          </li>
                          <li>
                            <strong>・</strong> Paging System: Supports instant
                            announcements and alerts to specific areas or the
                            entire facility, ensuring timely dissemination of
                            important information.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>2. Broadcast Capabilities:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> The system allows for the
                            broadcasting of emergency alerts, safety
                            instructions, and general announcements, ensuring
                            that all personnel are informed and can respond
                            appropriately.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>3. General Alarm Functionality:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> The PAGA system is equipped with
                            a robust alarm feature that can be activated during
                            emergencies, providing clear audio signals to alert
                            personnel and guide them in evacuation or emergency
                            procedures.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>4. Scalability:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Designed to accommodate large
                            properties, the PAGA system is scalable and can be
                            tailored to meet the specific needs of various
                            environments, whether a small facility or a vast
                            offshore platform.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>5. Reliability:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Built to operate in challenging
                            conditions, the PAGA system ensures continuous
                            operation during critical situations, providing
                            peace of mind and enhancing safety protocols.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>6. User-Friendly Interface:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> The system is designed for ease
                            of use, allowing personnel to operate and access
                            features with minimal training, ensuring quick
                            response during emergencies.
                          </li>
                        </ul>
                      </div>

                      <div>
                        <strong>7. Multi-Lingual Support:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Supports multiple languages for
                            diverse workforces, enhancing clarity and
                            comprehension during announcements and alarms.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>Applications:</h5>
                      <ul>
                        <li>
                          <strong>・</strong> Education: Ensuring the safety of
                          students and staff during emergencies.
                        </li>
                        <li>
                          <strong>・</strong> Healthcare: Facilitating rapid
                          communication in hospitals and clinics.
                        </li>
                        <li>
                          <strong>・</strong> Transportation: Providing timely
                          information to passengers in train stations and ports.
                        </li>
                        <li>
                          <strong>・</strong> Commercial Buildings: Enhancing
                          internal communication and safety protocols.
                        </li>
                        <li>
                          <strong>・</strong> Offshore Platforms: Critical for
                          coordinating operations and ensuring safety in remote
                          environments.
                        </li>
                      </ul>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      KNTECH's PAGA system is a vital component of the offshore
                      platform's communication infrastructure. By integrating
                      advanced technologies for internal communication,
                      emergency alerts, and general announcements, the system
                      significantly enhances safety and operational efficiency
                      across large properties. With its robust features and
                      reliability, the PAGA system is an essential investment
                      for any facility prioritizing safety and effective
                      communication.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default PagaSystemPage;
