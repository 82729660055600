import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import newsData from '../data/news.json';

import Header from './components/Header';
import Footer from './components/Footer';

const NewsPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    setNews(newsData);
  }, []);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | NEWS & EVENTS</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='news' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>News & Events</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        <section className='py-5'>
          <div className='auto-container'>
            <div className='row'>
              {news.map((item, i) => (
                <div
                  className='news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp'
                  data-wow-delay='600ms'
                  key={i}
                >
                  <div className='inner-box'>
                    <div className='image-box'>
                      <figure className='image'>
                        <Link to={`/news/${item.id}`}>
                          <img src='images/resource/news-3.jpg' alt='' />
                        </Link>
                      </figure>
                      <span className='date'>
                        <b>{item.day}</b> {item.month}
                      </span>
                    </div>
                    <div className='content-box'>
                      <ul className='post-info'>
                        <li>
                          <i className='fa fa-user'></i> by Admin
                        </li>
                      </ul>
                      <h3 className='title'>
                        <Link to={`/news/${item.id}`}>{item.title}</Link>
                      </h3>
                    </div>
                    <div className='bottom-box'>
                      <Link to={`/news/${item.id}`} className='read-more'>
                        Read More <i className='fa fa-long-arrow-alt-right'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              {news.length === 0 && (
                <div className='col text-center'>
                  <h3>No news available</h3>
                </div>
              )}
            </div>
          </div>
        </section>

        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default NewsPage;
