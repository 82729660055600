import React, { useEffect, useState } from 'react';

const ProductFilter = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState('all');

  const filterProducts = (products, categories, filter) => {
    return products.filter((product) => {
      if (filter === 'all') return true;
      const category = categories.find((cat) => cat.id === product.category);
      return category && category.class === filter;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('products.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCategories(data.categories);
        setProducts(data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const filteredProducts = filterProducts(products, categories, filter);

  return (
    <div id='product-category-selection' className='filters clearfix'>
      <ul className='filter-tabs filter-btns clearfix'>
        <li
          className={`filter ${filter === 'all' ? 'active' : ''}`}
          onClick={() => setFilter('all')}
        >
          All
        </li>
        {categories.map((category, i) => (
          <li
            key={i}
            className='filter'
            onClick={() => setFilter(category.class)}
          >
            {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
          </li>
        ))}
      </ul>

      <div id='products-display' className='filter-list row'>
        {filteredProducts.map((product, i) => {
          const category = categories.find(
            (cat) => cat.id === product.category
          );
          return (
            <div
              key={i}
              className={`product-block ${category?.class} col-lg-4 col-md-6col-sm-12`}
            >
              <div className='inner-box'>
                <div className='image'>
                  <a href='#'>
                    <img
                      src={`./images/products/${category?.name}/${product.name}.png`}
                      alt={product.name}
                    />
                  </a>
                </div>
                <div className='content'>
                  <h4>{product.name}</h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// The filterProducts function can be imported or defined above this component.
export default ProductFilter;
