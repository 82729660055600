import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SolutionsSelection = (selection) => {
  console.log(selection);
  return (
    <>
      {/* <!--Start Services Details Sidebar--> */}
      <div className='col-xl-4 col-lg-4'>
        <div className='service-sidebar'>
          {/* <!--Start Services Details Sidebar Single--> */}
          <div className='sidebar-widget service-sidebar-single'>
            <div
              className='service-sidebar wow fadeInUp'
              data-wow-delay='0.1s'
              data-wow-duration='1200m'
            >
              <div className='service-list'>
                <ul>
                  <li>
                    <Link
                      to='/solutions/telecommunication-products'
                      className={`${
                        selection.selection == 'telecommunication-products'
                          ? 'current'
                          : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>Telecommunication Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/solutions/hotel-intercom'
                      className={`${
                        selection.selection == 'hotel-intercom' ? 'current' : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>Hotel Intercom</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/solutions/residential-intercom'
                      className={`${
                        selection.selection == 'residential-intercom'
                          ? 'current'
                          : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>Residential Intercom</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/solutions/office-intercom'
                      className={`${
                        selection.selection == 'office-intercom'
                          ? 'current'
                          : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>Office Intercom</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/solutions/paga-system'
                      className={`${
                        selection.selection == 'paga-system' ? 'current' : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>PAGA System</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to='/solutions/elevator-intercom'
                      className={`${
                        selection.selection == 'elevator-intercom'
                          ? 'current'
                          : ''
                      }`}
                    >
                      <i className='fas fa-angle-right'></i>
                      <span>Elevator Intercom</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <!--End Services Details Sidebar Single--> */}
          <div className='sidebar-widget banner-widget'>
            <div
              className='widget-content'
              style={{
                backgroundImage: 'url(../images/resource/contact.jpg)',
              }}
            >
              <div
                className='shape'
                style={{
                  backgroundImage: 'url(../images/resource/overlay-shape.png)',
                }}
              ></div>
              <div className='content-box'>
                <div className='icon-box'>
                  <i className='lnr lnr-icon-pie-chart'></i>
                </div>
                <h3>Your Professional UC solutions</h3>
                <a
                  href='page-contact.html'
                  className='theme-btn btn-style-two bg-light'
                >
                  <span className='btn-title text-black'>Contact us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Services Details Sidebar--> */}
    </>
  );
};

export default SolutionsSelection;
