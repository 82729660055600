// src/components/Header.js
import React from 'react';

import { Link } from 'react-router-dom';

const Header = (selection) => {
  return (
    <>
      {/* <!-- Main Header--> */}
      <header className='main-header header-style-two'>
        {/* <!-- Header Top --> */}
        <div className='header-top'>
          <div className='inner-container'>
            <div className='top-left'>
              {/* <!-- Info List --> */}
              <ul className='list-style-one'>
                <li>
                  <i className='fa fa-envelope'></i>
                  <a href='mailto:enquiry@startechdistribution.com'>
                    enquiry@startechdistribution.com
                  </a>
                </li>
                <li>
                  <i className='fa fa-map-marker-alt'></i> No.31-2, Petaling
                  Utama Avenue, Jalan PJS 1/50, Taman Petaling Utama, 46150
                  Petaling Jaya, Selangor, Malaysia.
                </li>
              </ul>
            </div>

            <div className='top-right'>
              <ul className='useful-links'>
                <li>
                  <Link to={'/contact'}>
                    <u>Contact Us</u>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className='outer-box'>
            <ul className='social-icon-one'>
              <li>
                <a
                  href='https://www.linkedin.com/company/startechdistribution'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='fab fa-linkedin'></span>
                </a>
              </li>
              <li>
                <a
                  href='https://www.facebook.com/startechdistributionsb/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='fab fa-facebook-square'></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Header Top --> */}

        {/* <!-- Header Lower --> */}
        <div className='header-lower'>
          {/* <!-- Main box --> */}
          <div className='main-box'>
            <div className='logo-box'>
              <div className='logo'>
                <Link to={'/'}>
                  <img
                    src='/images/startech_logo.png'
                    alt=''
                    title='Startech'
                  />
                </Link>
              </div>
            </div>

            {/* <!--Nav Box--> */}
            <div className='nav-outer'>
              <nav className='nav main-menu'>
                <ul className='navigation'>
                  <li
                    className={`${
                      selection.selection == 'about' ? 'current' : ''
                    }`}
                  >
                    <Link to={'/about'}>About Us</Link>
                  </li>
                  <li
                    className={`${
                      selection.selection == 'news' ? 'current' : ''
                    }`}
                  >
                    <Link to={'/news'}>News & Events</Link>
                  </li>
                  <li
                    className={`${
                      selection.selection == 'portfolio' ? 'current' : ''
                    }`}
                  >
                    <Link to={'/portfolio'}>Portfolio</Link>
                  </li>
                  <li
                    className={`${
                      selection.selection == 'products' ? 'current' : ''
                    } dropdown`}
                  >
                    <Link to={'/products'}>Products</Link>
                    <ul>
                      <li className='dropdown'>
                        <a href='#'>Contact Center</a>
                        <ul>
                          <li>
                            <a className='fw-bold' href='#'>
                              Unified Communication
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Integrated various communication tools for
                              seamless interaction.
                            </div>
                          </li>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://telrranet.com/'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Telrranet On-Prem
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Full featured contact center solution.
                            </div>
                          </li>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://telrranet.com/'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Telrranet Cloud
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Cloud Contact Center: Secure, Dedicated, and
                              Managed.
                            </div>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Enterprise Session Border Controller
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Secures and manages IP voice and video
                              communications.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className='dropdown'>
                        <a href='#'>Communication & Collaboration </a>
                        <ul>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://en.newrocktech.com/product/info_itemid_386.html'
                              target='_blank'
                              rel='noreferrer'
                            >
                              NewLync
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Digital collaboration platform offering integrated
                              communication and collaboration tools for
                              enterprises.
                            </div>
                          </li>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://www.nxcloud.com/'
                              target='_blank'
                              rel='noreferrer'
                            >
                              NXCLOUD
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Communication solutions and collaboration that
                              simplify global customer engagement for businesses
                              of all sizes.
                            </div>
                          </li>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://telrranet.com/'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Telrranet App
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Call, meet, message, and more-all in one app.
                            </div>
                          </li>
                          <li>
                            <a
                              className='fw-bold'
                              href='https://en.newrocktech.com/product/info_itemid_387.html'
                              target='_blank'
                              rel='noreferrer'
                            >
                              OMX
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Unified Communication platform, high-capacity
                              cloud PBX.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className='dropdown'>
                        <a href='#'>Services</a>
                        <ul>
                          <li>
                            <a className='fw-bold' href='#'>
                              Maintenance and Support
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Comprehensive, fast and effective support.
                            </div>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Professional Services
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Consult with the experts to create your best
                              customer journey.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className='dropdown'>
                        <a href='#'>Devices & Phones </a>
                        <ul>
                          <li>
                            <a className='fw-bold' href='#'>
                              Devices Catalog
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Explore our complete portfolio of devices for
                              individuals and conference rooms.
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${
                      selection.selection == 'solutions' ? 'current' : ''
                    } dropdown`}
                  >
                    <Link to={'/solutions'}>Solutions</Link>
                    <ul>
                      <li className='dropdown'>
                        <a href='#'>Business Need</a>
                        <ul>
                          <li>
                            <a className='fw-bold' href='#'>
                              Innovation Without Disruption
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Progress at your own speed with Startech’s hybrid
                              cloud solutions.
                            </div>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Customer Experience
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Provide seamless experiences for both customers
                              and employees at every interaction.
                            </div>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Remote Contact Center
                            </a>
                            <div
                              style={{ padding: '0px 25px', fontSize: '14px' }}
                            >
                              Ensure your contact center operates efficiently,
                              regardless of your employees' locations.
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className='dropdown'>
                        <a href='#'>Industry</a>
                        <ul>
                          <li>
                            <a className='fw-bold' href='#'>
                              Telecommunication
                            </a>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Hospitality, Office and Business
                            </a>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Industrial Emergency Phone
                            </a>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Oil and Gas
                            </a>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Hotel and Residential
                            </a>
                          </li>
                          <li>
                            <a className='fw-bold' href='#'>
                              Education and Financial Institution
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`${
                      selection.selection == 'technical' ? 'current' : ''
                    }`}
                  >
                    <Link to={'/technical-center'}>Technical Center</Link>
                  </li>
                </ul>
              </nav>
              {/* <!-- Main Menu End--> */}
            </div>

            <div className='outer-box'>
              {/* <!-- Mobile Nav toggler --> */}
              <div className='mobile-nav-toggler'>
                <span className='icon lnr-icon-bars'></span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Header Lower --> */}

        {/* <!-- Mobile Menu  --> */}
        <div className='mobile-menu'>
          <div className='menu-backdrop'></div>

          {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
          <nav className='menu-box'>
            <div className='upper-box'>
              <div className='nav-logo'>
                <Link to={'/'}>
                  <img src='/images/startech_logo.png' alt='' title='' />
                </Link>
              </div>
              <div className='close-btn'>
                <i className='icon fa fa-times'></i>
              </div>
            </div>

            <ul className='navigation clearfix'>
              {/* <!--Keep This Empty / Menu will come through Javascript--> */}
            </ul>
            <ul className='contact-list-one'>
              <li>
                {/* <!-- Contact Info Box --> */}
                <div className='contact-info-box'>
                  <i className='icon lnr-icon-phone-handset'></i>
                  <span className='title'>Call Now</span>
                  <a href='tel:+60377828826'> +603-7782 8826 </a>
                  <br />
                  <a
                    href={`https://api.whatsapp.com/send/?phone=60126232077`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    +6012-6232077{' '}
                  </a>
                </div>
              </li>
              <li>
                {/* <!-- Contact Info Box --> */}
                <div className='contact-info-box'>
                  <span className='icon lnr-icon-envelope1'></span>
                  <span className='title'>Send Email</span>
                  <a href='mailto:enquiry@startechdistribution.com'>
                    enquiry@startechdistribution.com
                  </a>
                </div>
              </li>
            </ul>

            <ul className='social-links'>
              <li>
                <a
                  href='https://www.linkedin.com/company/startechdistribution'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-linkedin'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.facebook.com/startechdistributionsb/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-facebook-f'></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {/* <!-- End Mobile Menu --> */}

        {/* <!-- Sticky Header  --> */}
        <div className='sticky-header'>
          <div className='auto-container'>
            <div className='inner-container'>
              {/* <!--Logo--> */}
              <div className='logo'>
                <Link to={'/'} title=''>
                  <img src='/images/startech_logo.png' alt='' title='' />
                </Link>
              </div>

              {/* <!--Right Col--> */}
              <div className='nav-outer'>
                {/* <!-- Main Menu --> */}
                <nav className='main-menu'>
                  <div className='navbar-collapse show collapse clearfix'>
                    <ul className='navigation clearfix'>
                      {/* <!--Keep This Empty / Menu will come through Javascript--> */}
                    </ul>
                  </div>
                </nav>
                {/* <!-- Main Menu End--> */}

                {/* <!--Mobile Navigation Toggler--> */}
                <div className='mobile-nav-toggler'>
                  <span className='icon lnr-icon-bars'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Sticky Menu --> */}
      </header>
      {/* <!--End Main Header --> */}
    </>
  );
};

export default Header;
