import { FloatingWhatsApp } from 'react-floating-whatsapp';

export default function WhatsappButton() {
  return (
    <FloatingWhatsApp
      accountName={`STAR TECH`}
      phoneNumber={`60126232077`}
      buttonStyle={{ right: '0.85rem', bottom: '4.5rem' }}
      avatar={`images/wa_logo.png`}
    />
  );
}
