import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const TelecommunicationProductsPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | TELECOMMUNICATION PRODUCTS</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='telecommunication-products' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/1.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      <h5>
                        1. IP PBX (Internet Protocol Private Branch Exchange)
                      </h5>
                      <p>
                        <strong>Overview:</strong> An IP PBX is a telephone
                        system that uses internet protocol to manage calls and
                        routing within an organization. It replaces traditional
                        PBX systems that rely on analog lines.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong>Supports VoIP (Voice over
                            Internet Protocol) calls, allowing for
                            cost-effective communication.
                          </li>
                          <li>
                            <strong>・</strong>
                            Offers features like call forwarding, voicemail,
                            call recording, and auto-attendant.
                          </li>
                          <li>
                            <strong>・</strong>
                            Scalable and flexible, accommodating various user
                            needs and integration with other systems (CRM,
                            etc.).
                          </li>
                          <li>
                            <strong>・</strong>
                            Typically includes web-based management interfaces
                            for easy configuration and monitoring.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='my-4'>
                      <h5>2. Analog Phones</h5>
                      <p>
                        <strong>Overview:</strong> Traditional telephones that
                        connect to a standard telephone line (PSTN - Public
                        Switched Telephone Network).
                      </p>
                      <p>
                        <strong>Key Features:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>・</strong>
                          Simple and reliable technology with basic calling
                          functionalities.
                        </li>
                        <li>
                          <strong>・</strong>
                          Typically less expensive than modern VoIP solutions
                          but limited in features.
                        </li>
                        <li>
                          <strong>・</strong>
                          Easy to set up and use without the need for extensive
                          technical knowledge.
                        </li>
                      </ul>
                    </div>
                    <div className='my-4'>
                      <h5>
                        3. SIP Phones (Session Initiation Protocol Phones)
                      </h5>
                      <p>
                        <strong>Overview:</strong> SIP phones are specialized
                        devices that use SIP to initiate and manage voice and
                        video calls over the internet.
                      </p>
                      <p>
                        <strong>Key Features:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>・</strong>
                          Compatible with IP PBX systems, allowing for advanced
                          communication features.
                        </li>
                        <li>
                          <strong>・</strong>
                          Can be either hardware-based (dedicated devices) or
                          software-based (softphones).
                        </li>
                        <li>
                          <strong>・</strong>
                          Offer features like HD audio quality, video calling,
                          and integration with various applications.
                        </li>
                        <li>
                          <strong>・</strong>
                          Typically provide enhanced security features and
                          support multiple lines.
                        </li>
                      </ul>
                    </div>
                    <div className='my-4'>
                      <h5>4. Audio & Video Conferencing Solutions</h5>
                      <p>
                        <strong>Overview:</strong> Tools that enable real-time
                        communication among multiple participants through audio
                        and video feeds, often used for meetings and
                        collaboration.
                      </p>
                      <p>
                        <strong>Key Features:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>・</strong>
                          Support for high-definition video and clear audio to
                          enhance communication quality.
                        </li>
                        <li>
                          <strong>・</strong>
                          Features like screen sharing, virtual whiteboards, and
                          recording capabilities.
                        </li>
                        <li>
                          <strong>・</strong>
                          Integrations with calendars and other productivity
                          tools to streamline meeting scheduling.
                        </li>
                        <li>
                          <strong>・</strong>
                          Can be hosted on-premises or in the cloud, offering
                          flexibility for different business needs.
                        </li>
                      </ul>
                    </div>
                    <div className='my-4'>
                      <h5>5. Professional Communication Headsets</h5>
                      <p>
                        <strong>Overview:</strong> Specialized headsets designed
                        for use in professional environments, particularly in
                        call centers, remote work, and conferencing.
                      </p>
                      <p>
                        <strong>Key Features:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>・</strong>
                          Offer noise-canceling technology to reduce background
                          noise, ensuring clear communication.
                        </li>
                        <li>
                          <strong>・</strong>
                          Comfortable designs for prolonged use, often with
                          adjustable microphones.
                        </li>
                        <li>
                          <strong>・</strong>
                          Connectivity options including USB, 3.5mm jack, and
                          Bluetooth for versatility.
                        </li>
                        <li>
                          <strong>・</strong>
                          May include controls for volume, mute, and call
                          management for added convenience.
                        </li>
                      </ul>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      These telecommunication products collectively enhance
                      communication efficiency and collaboration in both
                      personal and professional settings. Organizations can
                      select the appropriate combination of these products based
                      on their specific communication needs, scalability, and
                      budget constraints.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default TelecommunicationProductsPage;
