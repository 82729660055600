import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const PortfolioPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | PORTFOLIO</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='portfolio' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Portfolio</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- Gallery Section --> */}
        <section className=''>
          <div className='container'>
            <div className='row g-3'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>Duke Highway Projects</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Duke Highway Projects/Edited-10-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Duke Highway Projects/Edited-10-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>Dynasty Hotel (KL) Projects</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-4-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-4-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-6-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-6-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-7-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-7-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-8-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Dynasty Hotel (KL) Projects/Edited-8-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>KL Hotel Projects</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/KL Hotel Projects/KL-Hotel-Project-1.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/KL Hotel Projects/KL-Hotel-Project-1.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/KL Hotel Projects/KL-Hotel-Project-2.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/KL Hotel Projects/KL-Hotel-Project-2.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>Plus Highway Projects</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Plus Highway Projects/Plus-Highway-1.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Plus Highway Projects/Plus-Highway-1.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Plus Highway Projects/Plus-Highway-2.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Plus Highway Projects/Plus-Highway-2.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Plus Highway Projects/Plus-Highway-3.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Plus Highway Projects/Plus-Highway-3.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Plus Highway Projects/Plus-Highway-4.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Plus Highway Projects/Plus-Highway-4.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Plus Highway Projects/Plus-Highway-5.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Plus Highway Projects/Plus-Highway-5.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>Telecom Cambodia Project</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Telecom Cambodia Project/Edited-3-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Telecom Cambodia Project/Edited-3-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Telecom Cambodia Project/Edited-5-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Telecom Cambodia Project/Edited-5-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Telecom Cambodia Project/Edited-9-rotated.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Telecom Cambodia Project/Edited-9-rotated.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Telecom Cambodia Project/Telecom-Cambodia-1.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Telecom Cambodia Project/Telecom-Cambodia-1.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-title'>
                      <h4>Wisma Timah (JKR) Projects</h4>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Wisma Timah (JKR) Projects/PJDJKR.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Wisma Timah (JKR) Projects/PJDJKR.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    {/* <!-- Project Block --> */}
                    <div className='project-block'>
                      <div className='inner-box'>
                        <figure className='image'>
                          <a
                            href='images/Portfolio/Wisma Timah (JKR) Projects/PJDJKR2.jpg'
                            className='lightbox-image'
                          >
                            <img
                              src='images/Portfolio/Wisma Timah (JKR) Projects/PJDJKR2.jpg'
                              alt=''
                            />
                          </a>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Gallery Section --> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default PortfolioPage;
