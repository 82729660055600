// src/components/Header.js
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import WhatsappButton from './components/WhatsappButton';

const HomePage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | HOME</title>
      </Helmet>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const name = encodeURIComponent(event.currentTarget.name.value);
    const email = encodeURIComponent(event.currentTarget.email.value);
    const website = encodeURIComponent(event.currentTarget.website.value);
    const message = encodeURIComponent(event.currentTarget.message.value);
    const subject = `Enquiry from ${name}`;

    const body = `Name: ${name}%0AEmail: ${email}%0AWebsite: ${website}%0A%0AMessage:%0A${message}%0A%0A`;

    window.location.href = `mailto:enquiry@startechdistribution.com?subject=${subject}&body=${body}`;
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/plugins/revolution/js/jquery.themepunch.revolution.min.js',
      '/plugins/revolution/js/jquery.themepunch.tools.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.actions.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.carousel.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.kenburn.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.layeranimation.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.migration.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.navigation.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.parallax.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.slideanims.min.js',
      '/plugins/revolution/js/extensions/revolution.extension.video.min.js',
      '/js/main-slider-script.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);

    // return () => {
    //   scripts.forEach((script) => {
    //     const existingScript = document.querySelector(
    //       `script[src="${script}"]`
    //     );
    //     if (existingScript) {
    //       document.body.removeChild(existingScript);
    //     }
    //   });
    // };
  }, []);

  return (
    <>
      <WhatsappButton />
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}

        {/* <!-- Main Header--> */}
        <header className='main-header header-style-six'>
          {/* <!-- Header Top --> */}
          <div className='header-top'>
            <div className='inner-container'>
              <div className='top-left'>
                {/* <!-- Info List --> */}
                <ul className='list-style-one'>
                  <li>
                    <i className='fa fa-envelope'></i>
                    <a href='mailto:enquiry@startechdistribution.com'>
                      enquiry@startechdistribution.com
                    </a>
                  </li>
                  <li>
                    <i className='fa fa-map-marker-alt'></i> No.31-2, Petaling
                    Utama Avenue, Jalan PJS 1/50, Taman Petaling Utama, 46150
                    Petaling Jaya, Selangor, Malaysia.
                  </li>
                </ul>
              </div>
            </div>

            <div className='outer-box'>
              <ul className='social-icon-one'>
                <li>
                  <a
                    href='https://www.linkedin.com/company/startechdistribution'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='fab fa-linkedin'></span>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/startechdistributionsb/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='fab fa-facebook-square'></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- Header Top --> */}

          {/* <!-- Header Lower --> */}
          <div className='header-lower'>
            {/* <!-- Main box --> */}
            <div className='main-box'>
              <div className='logo-box'>
                <div className='logo'>
                  <Link to={'/'}>
                    <img
                      src='images/startech_logo.png'
                      alt=''
                      title='Startech'
                      style={{ width: '240px' }}
                    />
                  </Link>
                </div>
              </div>

              {/* <!--Nav Box--> */}
              <div className='nav-outer'>
                <nav className='nav main-menu'>
                  <ul className='navigation'>
                    <li>
                      <Link to={'/about'}>About Us</Link>
                    </li>
                    <li>
                      <Link to={'/news'}>News & Events</Link>
                    </li>
                    <li>
                      <Link to={'/portfolio'}>Portfolio</Link>
                    </li>
                    <li className='dropdown'>
                      <Link to={'/products'}>Products</Link>
                      <ul>
                        <li className='dropdown'>
                          <a>Contact Center</a>
                          <ul>
                            <li>
                              <a className='fw-bold'>Unified Communication</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Integrated various communication tools for
                                seamless interaction.
                              </div>
                            </li>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://telrranet.com/'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Telrranet On-Prem
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Full featured contact center solution.
                              </div>
                            </li>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://telrranet.com/'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Telrranet Cloud
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Cloud Contact Center: Secure, Dedicated, and
                                Managed.
                              </div>
                            </li>
                            <li>
                              <a className='fw-bold'>
                                Enterprise Session Border Controller
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Secures and manages IP voice and video
                                communications.
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className='dropdown'>
                          <a>Communication & Collaboration </a>
                          <ul>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://en.newrocktech.com/product/info_itemid_386.html'
                                target='_blank'
                                rel='noreferrer'
                              >
                                NewLync
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Digital collaboration platform offering
                                integrated communication and collaboration tools
                                for enterprises.
                              </div>
                            </li>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://www.nxcloud.com/'
                                target='_blank'
                                rel='noreferrer'
                              >
                                NXCLOUD
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Communication solutions and collaboration that
                                simplify global customer engagement for
                                businesses of all sizes.
                              </div>
                            </li>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://telrranet.com/'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Telrranet App
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Call, meet, message, and more-all in one app.
                              </div>
                            </li>
                            <li>
                              <a
                                className='fw-bold'
                                href='https://en.newrocktech.com/product/info_itemid_387.html'
                                target='_blank'
                                rel='noreferrer'
                              >
                                OMX
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Unified Communication platform, high-capacity
                                cloud PBX.
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className='dropdown'>
                          <a>Services</a>
                          <ul>
                            <li>
                              <a className='fw-bold'>Maintenance and Support</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Comprehensive, fast and effective support.
                              </div>
                            </li>
                            <li>
                              <a className='fw-bold'>Professional Services</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Consult with the experts to create your best
                                customer journey.
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className='dropdown'>
                          <a>Devices & Phones </a>
                          <ul>
                            <li>
                              <a className='fw-bold'>Devices Catalog</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Explore our complete portfolio of devices for
                                individuals and conference rooms.
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown'>
                      <Link to={'/solutions'}>Solutions</Link>
                      <ul>
                        <li className='dropdown'>
                          <a>Business Need</a>
                          <ul>
                            <li>
                              <a className='fw-bold'>
                                Innovation Without Disruption
                              </a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Progress at your own speed with Startech’s
                                hybrid cloud solutions.
                              </div>
                            </li>
                            <li>
                              <a className='fw-bold'>Customer Experience</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Provide seamless experiences for both customers
                                and employees at every interaction.
                              </div>
                            </li>
                            <li>
                              <a className='fw-bold'>Remote Contact Center</a>
                              <div
                                style={{
                                  padding: '0px 25px',
                                  fontSize: '14px',
                                }}
                              >
                                Ensure your contact center operates efficiently,
                                regardless of your employees' locations.
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className='dropdown'>
                          <a>Industry</a>
                          <ul>
                            <li>
                              <a className='fw-bold'>Telecommunication</a>
                            </li>
                            <li>
                              <a className='fw-bold'>
                                Hospitality, Office and Business
                              </a>
                            </li>
                            <li>
                              <a className='fw-bold'>
                                Industrial Emergency Phone
                              </a>
                            </li>
                            <li>
                              <a className='fw-bold'>Oil and Gas</a>
                            </li>
                            <li>
                              <a className='fw-bold'>Hotel and Residential</a>
                            </li>
                            <li>
                              <a className='fw-bold'>
                                Education and Financial Institution
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={'/technical-center'}>Technical Center</Link>
                    </li>
                  </ul>
                </nav>
                {/* <!-- Main Menu End--> */}

                <div className='outer-box'>
                  <div className='ui-btn-outer'></div>

                  <a href='tel:+60377828826' className='info-btn'>
                    <i className='icon fa fa-phone'></i>
                    <small>Call Us</small>
                    <br />
                    +603-7782 8826
                  </a>

                  {/* <!-- Mobile Nav toggler --> */}
                  <div className='mobile-nav-toggler'>
                    <span className='icon lnr-icon-bars'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Header Lower --> */}

          {/* <!-- Mobile Menu  --> */}
          <div className='mobile-menu'>
            <div className='menu-backdrop'></div>

            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
            <nav className='menu-box'>
              <div className='upper-box'>
                <div className='nav-logo'>
                  <Link to={'/'}>
                    <img src='images/startech_logo.png' alt='' title='' />
                  </Link>
                </div>
                <div className='close-btn'>
                  <i className='icon fa fa-times'></i>
                </div>
              </div>

              <ul className='navigation clearfix'>
                {/* <!--Keep This Empty / Menu will come through Javascript--> */}
              </ul>
              <ul className='contact-list-one'>
                <li>
                  {/* <!-- Contact Info Box --> */}
                  <div className='contact-info-box'>
                    <i className='icon lnr-icon-phone-handset'></i>
                    <span className='title'>Call Now</span>
                    <a href='tel:+60377828826'> +603-7782 8826 </a>
                    <br />
                    <a
                      href={`https://api.whatsapp.com/send/?phone=60126232077`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {' '}
                      +6012-6232077{' '}
                    </a>
                  </div>
                </li>
                <li>
                  {/* <!-- Contact Info Box --> */}
                  <div className='contact-info-box'>
                    <span className='icon lnr-icon-envelope1'></span>
                    <span className='title'>Send Email</span>
                    <a href='mailto:enquiry@startechdistribution.com'>
                      enquiry@startechdistribution.com
                    </a>
                  </div>
                </li>
              </ul>

              <ul className='social-links'>
                <li>
                  <a
                    href='https://www.linkedin.com/company/startechdistribution'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-linkedin'></i>
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/startechdistributionsb/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fab fa-facebook-f'></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          {/* <!-- End Mobile Menu --> */}

          {/* <!-- Sticky Header  --> */}
          <div className='sticky-header'>
            <div className='auto-container'>
              <div className='inner-container'>
                {/* <!--Logo--> */}
                <div className='logo'>
                  <Link to={'/'} title=''>
                    <img src='images/startech_logo.png' alt='' title='' />
                  </Link>
                </div>

                {/* <!--Right Col--> */}
                <div className='nav-outer'>
                  {/* <!-- Main Menu --> */}
                  <nav className='main-menu'>
                    <div className='navbar-collapse show collapse clearfix'>
                      <ul className='navigation clearfix'>
                        {/* <!--Keep This Empty / Menu will come through Javascript--> */}
                      </ul>
                    </div>
                  </nav>
                  {/* <!-- Main Menu End--> */}

                  {/* <!--Mobile Navigation Toggler--> */}
                  <div className='mobile-nav-toggler'>
                    <span className='icon lnr-icon-bars'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Sticky Menu --> */}
        </header>
        {/* <!--End Main Header --> */}

        {/* <!--Banner Section--> */}
        <section className='banner-section-six'>
          <div className='banner-carousel owl-carousel owl-theme'>
            {/* <!-- Slide Item --> */}
            <div className='slide-item'>
              <div
                className='bg-image'
                style={{ backgroundImage: 'url(images/banner/2.png)' }}
              ></div>
              <div className='auto-container'>
                <div className='content-box'>
                  <div className='inner-content'>
                    <div className='shape-line animate-1'></div>
                    <div className='anim-icons'>
                      <div className='shape-1 bounce-y animate-4'></div>
                      <div className='shape-2 bounce-x animate-5'></div>
                      <div className='shape-3 bounce-y animate-4'></div>
                    </div>
                    <span className='sub-title animate-1'>
                      <span className='inner'>WELCOME TO THE STAR TECH</span>
                    </span>
                    <h1 className='title animate-2 text-start px-5'>
                      Your Preferred <br />
                      Unified Communication <br />
                      Solutions Company.
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Slide Item --> */}
            <div className='slide-item'>
              <div
                className='bg-image'
                style={{
                  backgroundImage: 'url(images/main-slider/s1-2.jpg)',
                }}
              ></div>
              <div className='auto-container'>
                <div className='content-box'>
                  <div className='inner-content'>
                    <div className='shape-line animate-1'></div>
                    <div className='anim-icons'>
                      <div className='shape-1 bounce-y animate-4'></div>
                      <div className='shape-2 bounce-x animate-5'></div>
                      <div className='shape-3 bounce-y animate-4'></div>
                    </div>
                    <span className='sub-title animate-1'>
                      <span className='inner'>WELCOME TO THE STAR TECH</span>
                    </span>
                    <h1 className='title animate-2 text-start px-5'>
                      Your Preferred <br />
                      Professional Communication Products <br />
                      Distribution Company.
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Slide Item --> */}
            <div className='slide-item'>
              <div
                className='bg-image'
                style={{ backgroundImage: 'url(images/banner/1.png)' }}
              ></div>
              <div className='auto-container'>
                <div className='content-box'>
                  <div className='inner-content'>
                    <div className='shape-line animate-1'></div>
                    <div className='anim-icons'>
                      <div className='shape-1 bounce-y animate-4'></div>
                      <div className='shape-2 bounce-x animate-5'></div>
                      <div className='shape-3 bounce-y animate-4'></div>
                    </div>
                    <span className='sub-title animate-1'>
                      <span className='inner'>WELCOME TO THE STAR TECH</span>
                    </span>
                    <h1 className='title animate-2 text-start px-5'>
                      Mobile App : Telrranet <br />
                      Redesigned PBX for Mobile Flexibility
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--END Banner Section --> */}

        {/* <!-- About Section --> */}
        <section className='about-section-ten'>
          <div className='auto-container'>
            <div className='row'>
              <div
                className='content-column col-xl-6 col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight'
                data-wow-delay='600ms'
              >
                <div className='inner-column'>
                  <div className='sec-title'>
                    <span className='sub-title'>ABOUT OUR COMPANY</span>
                    <h2>
                      Best IT Technology Services <span>Since 2008</span>
                    </h2>
                    <h4>We listen ; We think ; We do.</h4>
                    <div className='text'>
                      Effective office communication is essential, regardless of
                      whether it's a small or large organization. With over 15
                      years of expertise in distributing telecommunication
                      products and over 8 years specializing in custom
                      telecommunication software development, we are
                      well-equipped to meet your needs.
                    </div>
                  </div>
                  <div className='info-box-list'>
                    <div className='info-box'>
                      {/* <!-- <i className="icon flaticon-business-012-startup"></i> --> */}
                      <img src='images/about/1.png' alt='' />
                      <h4 className='title'>Unified Communication</h4>
                      <div className='text'>
                        We comprehend your office communication requirements and
                        tailor solutions to align with your business objectives.
                      </div>
                    </div>
                    <div className='info-box'>
                      {/* <!-- <i className="icon flaticon-business-054-graph"></i> --> */}
                      <img src='images/about/2.png' alt='' />
                      <h4 className='title'>Robust communication system</h4>
                      <div className='text'>
                        Harness the capabilities of a comprehensive
                        communication system, offering flexibility and
                        streamlined management through our cloud-based solution.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Image Column --> */}
              <div className='image-column col-xl-6 col-lg-5 col-md-12 col-sm-12'>
                <div className='inner-column wow fadeInLeft'>
                  <figure className='image-1 overlay-anim wow fadeInUp'>
                    <img src='images/resource/about3-1.png' alt='' />
                  </figure>
                  <figure className='image-2 overlay-anim wow fadeInRight'>
                    <img src='images/resource/about3-2.png' alt='' />
                  </figure>
                  <div className='experience'>
                    <div className='inner'>
                      <div className='text'>
                        <strong>15+</strong> Years of <br />
                        experience
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='content-bottom'>
              <div className='inner'>
                <div className='call-info-box-outer'>
                  <div className='call-info-box-text'>
                    We Provide Full Office Assistance Anytime anywhere.
                    <span>Request A Quote</span>
                  </div>
                  <div className='call-info-box'>
                    <i className='icon fa fa-phone'></i>
                    <span>+603-7782 8826</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End About Section --> */}

        {/* <!-- Services Section Three --> */}
        <section className='services-section-three'>
          <div className='auto-container'>
            <div className='sec-title text-center'>
              <span className='sub-title'>Services we’re offering</span>
              <h2>
                High quality products and services <br />
                that we stand behind
              </h2>
            </div>
            <div className='outer-box'>
              <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-5 justify-content-center'>
                {/* <!-- Service Block Two --> */}
                <div className='service-block-three col wow fadeInUp'>
                  <div className='inner-box'>
                    {/* <!-- <i className="icon flaticon-business-002-graph"></i> --> */}
                    <i className='icon'>
                      <img src='images/services/1.png' alt='' />
                    </i>
                    <h4 className='title'>
                      <a href='page-service-details.html'>
                        Ensuring Value and Quality
                      </a>
                    </h4>
                    <div className='text'>
                      Affordable & Reasonable prices using best quality
                      products.
                    </div>
                  </div>
                </div>

                {/* <!-- Service Block Two --> */}
                <div
                  className='service-block-three col wow fadeInUp'
                  data-wow-delay='200ms'
                >
                  <div className='inner-box'>
                    {/* <!-- <i className="icon flaticon-business-010-startup"></i> --> */}
                    <i className='icon'>
                      <img src='images/services/2.png' alt='' />
                    </i>
                    <h4 className='title'>
                      <a href='page-service-details.html'>
                        Customized Communication Solutions
                      </a>
                    </h4>
                    <div className='text'>
                      Tailored phone and communication solutions designed to
                      fulfill unique business requirements.
                    </div>
                  </div>
                </div>

                {/* <!-- Service Block Two --> */}
                <div
                  className='service-block-three col wow fadeInUp'
                  data-wow-delay='400ms'
                >
                  <div className='inner-box'>
                    {/* <!-- <i className="icon flaticon-business-030-settings"></i> --> */}
                    <i className='icon'>
                      <img src='images/services/3.png' alt='' />
                    </i>
                    <h4 className='title'>
                      <a href='page-service-details.html'>
                        Installation Services
                      </a>
                    </h4>
                    <div className='text'>
                      Install office audio systems that optimize focus on your
                      business operations.
                    </div>
                  </div>
                </div>

                {/* <!-- Service Block Two --> */}
                <div
                  className='service-block-three col wow fadeInUp'
                  data-wow-delay='600ms'
                >
                  <div className='inner-box'>
                    {/* <!-- <i className="icon flaticon-business-045-stationery"></i> --> */}
                    <i className='icon'>
                      <img src='images/services/4.png' alt='' />
                    </i>
                    <h4 className='title'>
                      <a href='page-service-details.html'>
                        Comprehensive Client Support Solutions
                      </a>
                    </h4>
                    <div className='text'>
                      We provide a proficient and streamlined support system for
                      all our clients.
                    </div>
                  </div>
                </div>

                {/* <!-- Service Block Two --> */}
                <div
                  className='service-block-three col wow fadeInUp'
                  data-wow-delay='800ms'
                >
                  <div className='inner-box'>
                    {/* <!-- <i className="icon flaticon-business-054-graph"></i> --> */}
                    <i className='icon'>
                      <img src='images/services/5.png' alt='' />
                    </i>
                    <h4 className='title'>
                      <a href='page-service-details.html'>
                        Trusted Professional Team
                      </a>
                    </h4>
                    <div className='text'>
                      A professional team with more than a decade of experience.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section Three --> */}

        {/* <!-- Fun Fact Section --> */}
        <section
          className='fun-fact-section-three style-two'
          style={{ backgroundImage: 'url(./images/icons/shape-tm-5.png)' }}
        >
          <div className='auto-container'>
            <div className='fact-counter'>
              <div className='row'>
                {/* <!-- Counter block--> */}
                <div className='counter-block-four col-lg-3 col-md-6 col-sm-12 wow fadeInUp'>
                  <div className='inner'>
                    {/* <!-- <i className="icon flaticon-business-060-graph"></i> --> */}
                    <i className='icon'>
                      <img src='images/funfact/1.png' alt='' />
                    </i>
                    <div className='count-box'>
                      <span
                        className='count-text'
                        data-speed='3000'
                        data-stop='100'
                      >
                        0
                      </span>
                      <span
                        className='text-light'
                        style={{
                          fontSize: '48px',
                          fontWeight: '700',
                          lineHeight: '1em',
                        }}
                      >
                        +
                      </span>
                    </div>
                    <span className='counter-title'>
                      Type of communication products
                    </span>
                  </div>
                </div>

                {/* <!--Counter block--> */}
                <div
                  className='counter-block-four col-lg-3 col-md-6 col-sm-12 wow fadeInUp'
                  data-wow-delay='300ms'
                >
                  <div className='inner'>
                    {/* <!-- <i className="icon flaticon-business-035-helpline"></i> --> */}
                    <i className='icon'>
                      <img src='images/funfact/2.png' alt='' />
                    </i>
                    <div className='count-box'>
                      <span
                        className='count-text'
                        data-speed='3000'
                        data-stop='50'
                      >
                        0
                      </span>
                      <span
                        className='text-light'
                        style={{
                          fontSize: '48px',
                          fontWeight: '700',
                          lineHeight: '1em',
                        }}
                      >
                        +
                      </span>
                    </div>
                    <span className='counter-title'>Brands</span>
                  </div>
                </div>

                {/* <!--Counter block--> */}
                <div
                  className='counter-block-four col-lg-3 col-md-6 col-sm-12 wow fadeInUp'
                  data-wow-delay='600ms'
                >
                  <div className='inner'>
                    {/* <!-- <i className="icon flaticon-business-020-hierarchy"></i> --> */}
                    <i className='icon'>
                      <img src='images/funfact/3.png' alt='' />
                    </i>
                    <div className='count-box'>
                      <span
                        className='count-text'
                        data-speed='3000'
                        data-stop='1000'
                      >
                        0
                      </span>
                      <span
                        className='text-light'
                        style={{
                          fontSize: '48px',
                          fontWeight: '700',
                          lineHeight: '1em',
                        }}
                      >
                        +
                      </span>
                    </div>
                    <span className='counter-title'>Satisfied clients</span>
                  </div>
                </div>

                {/* <!--Counter block--> */}
                <div
                  className='counter-block-four col-lg-3 col-md-6 col-sm-12 wow fadeInUp'
                  data-wow-delay='900ms'
                >
                  <div className='inner'>
                    {/* <!-- <i className="icon flaticon-business-048-coin"></i> --> */}
                    <i className='icon'>
                      <img src='images/funfact/4.png' alt='' />
                    </i>
                    <div className='count-box'>
                      <span
                        className='count-text'
                        data-speed='3000'
                        data-stop='100'
                      >
                        0
                      </span>
                      <span
                        className='text-light'
                        style={{
                          fontSize: '48px',
                          fontWeight: '700',
                          lineHeight: '1em',
                        }}
                      >
                        %
                      </span>
                    </div>
                    <span className='counter-title'>Quality products</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Fun Fact Section --> */}

        {/* <!-- About Section --> */}
        <section className='about-section-nine'>
          <div className='auto-container'>
            <div className='tabs-box tabs-three'>
              {/* <!--Tabs Box--> */}
              <ul className='tab-buttons clearfix'>
                <li className='tab-btn active-btn' data-tab='#tab1'>
                  <span className='title'>Our Vision</span>
                </li>
                <li className='tab-btn' data-tab='#tab2'>
                  <span className='title'>Our Mission</span>
                </li>
                <li className='tab-btn' data-tab='#tab3'>
                  <span className='title'>Our History</span>
                </li>
              </ul>

              <div className='tabs-content'>
                {/* <!--Tab--> */}
                <div className='tab active-tab' id='tab1'>
                  <div className='row'>
                    <div className='blocks-column col-lg-6 col-md-6 col-sm-12'>
                      <div className='inner-column'>
                        <div className='sec-title'>
                          <h2>Be a 50-Years Thriving Technology Company</h2>
                          <div className='text'>
                            We are dedicated to unlocking boundless
                            opportunities for all, contributing to social
                            cohesion and unity through our efforts.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='image-column col-lg-6 col-md-6 col-sm-12'>
                      <div className='inner-column'>
                        <div className='info-box'>
                          <h3 className='title'>
                            We have over 15 years of experience
                          </h3>
                          <a className='read-more'>{`Read More >`}</a>
                        </div>

                        <div className='image-box'>
                          <figure className='image overlay-anim'>
                            <img src='images/resource/work-2.jpg' alt='' />
                          </figure>
                          <i className='icon flaticon-business-060-graph'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Tab--> */}
                <div className='tab' id='tab2'>
                  <div className='row'>
                    <div className='blocks-column col-lg-6 col-md-6 col-sm-12'>
                      <div className='inner-column'>
                        <div className='sec-title'>
                          <h2>Empowering People with Unified Communications</h2>
                          <div className='text'>
                            Our goal is to enhance communication across all
                            channels, enabling effective collaboration despite
                            geographical or technological challenges. By
                            fostering unity and efficiency, we empower teams and
                            organizations to achieve their goals more
                            seamlessly.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='image-column col-lg-6 col-md-6 col-sm-12'>
                      <div className='inner-column'>
                        <div className='info-box'>
                          <h3 className='title'>
                            We have over 15 years of experience
                          </h3>
                          <a className='read-more'>{`Read More >`}</a>
                        </div>

                        <div className='image-box'>
                          <figure className='image overlay-anim'>
                            <img src='images/resource/work-2.jpg' alt='' />
                          </figure>
                          <i className='icon flaticon-business-060-graph'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Tab--> */}
                <div className='tab' id='tab3'>
                  <div className='tab-our-history'>
                    <div className='row'>
                      <div className='image-column col-lg-6 col-md-6 col-sm-12'>
                        <div className='inner-column'>
                          <div className='image-box'>
                            <figure className='image overlay-anim'>
                              <img src='images/resource/work-2.jpg' alt='' />
                            </figure>
                            <i className='icon flaticon-business-060-graph'></i>
                          </div>

                          <div className='info-box'>
                            <h3 className='title'>
                              We have over 15 years of experience
                            </h3>
                            <a className='read-more'>{`Read More >`}</a>
                          </div>
                        </div>
                      </div>

                      <div className='blocks-column col-lg-6 col-md-6 col-sm-12'>
                        <div className='inner-column'>
                          {/* <!-- Feature Block Nine --> */}
                          <div className='feature-block-nine'>
                            <div className='inner-box'>
                              {/* <!-- <i className="icon flaticon-business-011-dollar"></i> --> */}
                              <i className='icon'>
                                <img src='images/history/1.png' alt='' />
                              </i>
                              <h4 className='title'>
                                <a href='page-service-details.html'>
                                  Best Business Solution
                                </a>
                              </h4>
                              <div className='text'>
                                Our commitment extends beyond delivering
                                exceptional services.
                              </div>
                            </div>
                          </div>

                          {/* <!-- Feature Block Nine --> */}
                          <div className='feature-block-nine'>
                            <div className='inner-box'>
                              {/* <!-- <i className="icon flaticon-business-012-startup"></i> --> */}
                              <i className='icon'>
                                <img src='images/history/2.png' alt='' />
                              </i>
                              <h4 className='title'>
                                <a href='page-service-details.html'>
                                  Highest Customer Value
                                </a>
                              </h4>
                              <div className='text'>
                                Close relationships with consumers and maintain
                                transparency, fairness, and openness to
                                dialogue.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- Map Section--> */}
        <section className='map-section'>
          <iframe
            className='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.058974448886!2d101.646799575676!3d3.0789306535788405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba58cb0843f%3A0xaa3a41584d27ce1a!2sStar%20Tech%20Distribution%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1721122292081!5m2!1sen!2smy'
            width='600'
            height='450'
            style={{ border: '0' }}
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='Star Tech Distribution'
          ></iframe>
        </section>
        {/* <!--End Map Section--> */}

        {/* <!-- Contact Section Three --> */}
        <section className='contact-section-three style-three'>
          <div className='auto-container'>
            <div className='row'>
              {/* <!-- Info Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12 order-2'>
                <div className='inner-column wow fadeInRight'>
                  <div className='sec-title'>
                    <div className='sub-title'>CONTACT US</div>
                    <h2>Contact Us Let’s Talk Your Any Query.</h2>
                  </div>

                  <div className='info-box-outer'>
                    <div className='time-table-box'>
                      <div className='inner'>
                        <h4 className='title'>Opening Hour</h4>
                        <ul>
                          <li>
                            Mon - Fri{' '}
                            <span className='time-table'>9am - 6pm</span>
                          </li>
                          <li>
                            Saturday <span className='time-table'>Closed</span>
                          </li>
                          <li>
                            Sunday <span className='time-table'>Closed</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='contact-details-info'>
                      <div className='inner'>
                        <h3>Visit Our Location</h3>
                        <div className='contact-details-block'>
                          <div className='inner-box'>
                            <i className='icon fa fa-phone'></i>
                            <div className='title'>
                              Looking For Consultation
                            </div>
                            <div className='text fs-6'>
                              +603-7782 8826 <br />
                              +6012-6232077
                            </div>
                          </div>
                        </div>
                        <div className='contact-details-block'>
                          <div className='inner-box'>
                            <i className='icon fa fa-map-marker-alt'></i>
                            <div className='title'>Visit Our Location</div>
                            <div className='text fs-6'>
                              No. 31-2, Petaling Utama Avenue, Jalan PJS 1/50,
                              Taman Petaling Utama, 46150, Petaling Jaya ,
                              Selangor. Malaysia.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Form Column --> */}
              <div className='form-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Contact Form --> */}
                  <div className='contact-form-two wow fadeInLeft'>
                    <div className='title-box'>
                      <h3>Have Any Questions</h3>
                      <span className='sub-title'>
                        Feel free to contact us through anywhere.
                      </span>
                    </div>
                    {/* <!--Contact Form--> */}
                    <form
                      id='contact-form'
                      action='mailto:enquiry@startechdistribution.com'
                      method='post'
                      enctype='text/plain'
                      autocomplete='off'
                      onSubmit={handleSubmit}
                    >
                      <div className='row gx-3'>
                        <div className='form-group col-lg-6 col-md-6 col-sm-12'>
                          <input
                            type='text'
                            name='name'
                            placeholder='Your Name'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-6 col-md-6 col-sm-12'>
                          <input
                            type='email'
                            name='email'
                            placeholder='Email Name'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <input
                            type='text'
                            name='website'
                            placeholder='Website'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <textarea
                            name='message'
                            placeholder='Your Message'
                            required
                          ></textarea>
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <button
                            className='theme-btn btn-style-one bg-dark'
                            type='submit'
                          >
                            <span className='btn-title'>GET SOLUTION</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <!--End Contact Form --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End Contact Section Three --> */}

        {/* <!-- Main Footer --> */}
        <footer className='main-footer'>
          <div
            className='bg-image'
            style={{ backgroundImage: 'url(./images/background/8-red.png)' }}
          ></div>

          {/* <!--Widgets Section--> */}
          <div className='widgets-section'>
            <div className='auto-container'>
              <div className='row'>
                {/* <!--Footer Column--> */}
                <div className='footer-column col-xl-4 col-lg-12 col-md-12 col-sm-12'>
                  <div className='footer-widget about-widget'>
                    <div className='logo'>
                      <Link to={'/'}>
                        {/* <!-- <img src="images/logo-red.png" alt=""/> --> */}
                        <img src='images/startech_logo.png' alt='' />
                      </Link>
                    </div>
                    <ul className='social-icon-two'>
                      <li>
                        <a
                          href='https://www.linkedin.com/company/startechdistribution'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <i className='fab fa-linkedin'></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.facebook.com/startechdistributionsb/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <i className='fab fa-facebook'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <!--Footer Column--> */}
                <div className='footer-column col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='footer-widget'>
                    <h3 className='widget-title'>Explore</h3>
                    <ul className='user-links'>
                      <li>
                        <Link to={'/about'}>About Us</Link>
                      </li>
                      <li>
                        <Link to={'/news'}>News & Events</Link>
                      </li>
                      <li>
                        <Link to={'/portfolio'}>Portfolio</Link>
                      </li>
                      <li>
                        <Link to={'/products'}>Products</Link>
                      </li>
                      <li>
                        <Link to={'/solutions'}>Solutions</Link>
                      </li>
                      <li>
                        <Link to={'/technical-center'}>Technical Center</Link>
                      </li>
                      <li>
                        <Link to={'/career'}>Career</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <!--Footer Column--> */}
                <div className='footer-column col-xl-4 col-lg-6 col-md-6 col-sm-12'>
                  <div className='footer-widget contact-widget'>
                    <h3 className='widget-title'>Contact</h3>
                    <div className='widget-content'>
                      <div className='text'>
                        No. 31-2, Petaling Utama Avenue, Jalan PJS 1/50, Taman
                        Petaling Utama, 46150, Petaling Jaya , Selangor.
                        Malaysia.
                      </div>
                      <ul className='contact-info'>
                        <li>
                          <i className='fa fa-envelope'></i>
                          <a
                            className='text-nowrap'
                            href='mailto:enquiry@startechdistribution.com'
                          >
                            enquiry@startechdistribution.com
                          </a>
                          <br />
                        </li>
                        <li>
                          <i className='fa fa-phone-square'></i>
                          <a href='tel:+60377828826'> +603-7782 8826 </a>
                          <br />
                        </li>
                        <li>
                          <i className='fa fa-phone-square'></i>
                          <a
                            href={`https://api.whatsapp.com/send/?phone=60126232077`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {' '}
                            +6012-6232077{' '}
                          </a>
                          <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--Footer Bottom--> */}
          <div className='footer-bottom'>
            <div className='auto-container'>
              <div className='inner-container bg-danger'>
                <div className='copyright-text text-light'>
                  © 2024 STAR TECH DISTRIBUTION SDN. BHD. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!--End Main Footer --> */}
      </div>
      {/* <!-- End Page Wrapper --> */}

      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default HomePage;
