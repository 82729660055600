import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const AboutPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | ABOUT US</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='about' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>About Us</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- About Section Six--> */}
        <section className='about-section'>
          <div className='auto-container'>
            <div className='row'>
              <div
                className='content-column col-xl-6 col-lg-7 col-md-12 col-sm-12 order-lg-2 wow fadeInRight'
                data-wow-delay='600ms'
              >
                <div className='inner-column'>
                  <div className='sec-title'>
                    <span className='sub-title'>WHO WE ARE</span>
                    <h3>
                      We provide the best Telecommunication Solution in region
                    </h3>
                    <div className='text'>
                      STAR TECH DISTRIBUTION incorporated since 04 September
                      2008, we strive to become the leading supplier of
                      telecommunication products and solutions in Malaysia. We
                      are committed to provide the best telecommunication
                      solutions to our customers.
                    </div>
                  </div>

                  <div className='btn-box'>
                    <a
                      href='./images/about/company_profile.pdf'
                      className='theme-btn btn-style-one w-100'
                      target='_blank'
                      download
                    >
                      <span className='btn-title'>Our Company Profile</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* <!-- Image Column --> */}
              <div className='image-column col-xl-6 col-lg-5 col-md-12 col-sm-12 pb-3'>
                <div className='inner-column wow fadeInLeft'>
                  <figure className='image-1 overlay-anim wow fadeInUp'>
                    <img src='images/about/4.png' alt='' />
                  </figure>
                  <figure className='image-2 overlay-anim wow fadeInRight'>
                    <img src='images/about/3.png' alt='' />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End About Section --> */}

        {/* <!-- Clients Section --> */}
        <section className='clients-section'>
          <div className='sec-title text-center'>
            <h2>Our Clients</h2>
          </div>
          <div className='auto-container'>
            {/* <!-- Sponsors Outer --> */}
            <div className='sponsors-outer'>
              {/* <!--clients carousel--> */}
              <ul className='clients-carousel owl-carousel owl-theme pt-1'>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/1.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/2.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/3.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/4.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/5.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/6.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/7.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/8.png' alt='' className='w-50' />
                  </a>
                </li>
                <li className='slide-item'>
                  <a href='#'>
                    <img src='images/clients/9.png' alt='' className='w-50' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <!--End Clients Section --> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default AboutPage;
