import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const ElevatorIntercomPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | ELEVATOR INTERCOM</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}

        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='elevator-intercom' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/6.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      <h5>1. Purpose and Importance</h5>
                      <p>
                        <strong>Overview:</strong> The Elevator Intercom system
                        is designed to provide reliable communication solutions
                        for passengers in elevators, particularly during
                        emergencies or maintenance situations. It ensures safety
                        and enhances the response capabilities of building
                        management and emergency services.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Emergency Communication: Allows
                            passengers to communicate directly with building
                            security or maintenance personnel in case of an
                            emergency, such as being trapped in the elevator.
                          </li>
                          <li>
                            <strong>・</strong> Maintenance Alerts: Provides a
                            direct line for reporting issues or malfunctions to
                            maintenance staff, facilitating timely repairs and
                            reducing downtime.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>2. Emergency Applications</h5>
                      <p>
                        <strong>Overview:</strong> The intercom system plays a
                        crucial role in emergency situations, enhancing
                        passenger safety and ensuring prompt responses.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Two-Way Communication:
                            Passengers can initiate two-way communication with
                            emergency response teams or building management,
                            providing real-time updates about their situation.
                          </li>
                          <li>
                            <strong>・</strong> Built-In Alarm Features: The
                            system can include alarm buttons that passengers can
                            press to signal distress, ensuring they can alert
                            others even without speaking.
                          </li>
                          <li>
                            <strong>・</strong> Integration with Security
                            Systems: Can be integrated with existing security
                            systems to enable faster dispatch of assistance when
                            emergencies occur.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>3. Maintenance Applications</h5>
                      <p>
                        <strong>Overview:</strong> The Elevator Intercom system
                        also supports maintenance operations, helping to keep
                        elevators in optimal working condition.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Direct Reporting: Allows users
                            or maintenance personnel to report issues
                            immediately, streamlining the troubleshooting and
                            repair processes.
                          </li>
                          <li>
                            <strong>・</strong> Scheduled Maintenance Alerts:
                            Can be programmed to remind staff of upcoming
                            maintenance checks, ensuring that elevators are
                            regularly serviced to prevent breakdowns.
                          </li>
                          <li>
                            <strong>・</strong> Real-Time Monitoring:
                            Maintenance teams can monitor the status of elevator
                            systems remotely, receiving alerts for any
                            malfunctions or irregularities.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>4. User-Friendly Design</h5>
                      <p>
                        <strong>Overview:</strong> The intercom system is
                        designed to be user-friendly and intuitive, ensuring
                        ease of use for all passengers.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Simple Interface: The intercom
                            interface is straightforward, typically featuring a
                            button to initiate communication and clear
                            instructions for use.
                          </li>
                          <li>
                            <strong>・</strong> Audio/Visual Indicators:
                            Equipped with audio alerts (such as ringing or
                            beeping) and visual indicators (such as lights) to
                            signal when the system is active or when help is on
                            the way.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>5. Compliance and Safety Standards</h5>
                      <p>
                        <strong>Overview:</strong> The Elevator Intercom system
                        is designed to comply with industry safety standards and
                        regulations, ensuring reliability and effectiveness.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Regulatory Compliance: Adheres
                            to local building codes and safety regulations for
                            emergency communication systems.
                          </li>
                          <li>
                            <strong>・</strong> Durability and Reliability:
                            Built to withstand harsh conditions, ensuring
                            consistent performance even in emergency situations.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      The Elevator Intercom system is a vital component of
                      building safety and maintenance protocols. By facilitating
                      effective communication during emergencies and
                      streamlining maintenance operations, it enhances the
                      safety and convenience of elevator use for passengers.
                      With its user-friendly design, compliance with safety
                      standards, and real-time communication capabilities, the
                      Elevator Intercom system is essential for any multi-story
                      building looking to prioritize passenger safety and
                      operational efficiency.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default ElevatorIntercomPage;
