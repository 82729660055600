import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const SolutionsPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- Services Section --> */}
        <section className=''>
          <div className='container'>
            <div className='row'>
              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/telecommunication-products'}>
                        <img src='images/solutions/1.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/1.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/telecommunication-products'}>
                        Telecommunication Products
                      </Link>
                    </h5>
                    <div className='text'>
                      IP PBX, Analog Phone, SIP Phone, Audio & Video
                      Conferencing, Professional Communication headset etc.
                    </div>
                    <Link
                      to={'/solutions/telecommunication-products'}
                      className='read-more'
                    >
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/hotel-intercom'}>
                        <img src='images/solutions/2.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/2.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/hotel-intercom'}>
                        Hotel Intercom
                      </Link>
                    </h5>
                    <div className='text'>
                      Easy to deploy and manageable via WEB interface.
                    </div>
                    <Link
                      to={'/solutions/hotel-intercom'}
                      className='read-more'
                    >
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/residential-intercom'}>
                        <img src='images/solutions/3.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/3.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/residential-intercom'}>
                        Residential Intercom
                      </Link>
                    </h5>
                    <div className='text'>
                      Excellent user experience as well as convenience and
                      security.
                    </div>
                    <Link
                      to={'/solutions/residential-intercom'}
                      className='read-more'
                    >
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/office-intercom'}>
                        <img src='images/solutions/4.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/4.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/office-intercom'}>
                        Office Intercom
                      </Link>
                    </h5>
                    <div className='text'>
                      Safe & secure server and gateway to store confidential
                      documents.
                    </div>
                    <Link
                      to={'/solutions/office-intercom'}
                      className='read-more'
                    >
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/paga-system'}>
                        <img src='images/solutions/5.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/5.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/paga-system'}>PAGA System</Link>
                    </h5>
                    <div className='text'>
                      PAGA (Public Address and General Alarm) an important part
                      of internal communication.
                    </div>
                    <Link to={'/solutions/paga-system'} className='read-more'>
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <!-- Service Block --> */}
              <div className='service-block col-lg-4 col-md-6 col-sm-12'>
                <div className='inner-box'>
                  <div className='image-box'>
                    <figure className='image'>
                      <Link to={'/solutions/elevator-intercom'}>
                        <img src='images/solutions/6.png' alt='' />
                      </Link>
                    </figure>
                    <div className='icon-box'>
                      <img src='images/solutions-icons/6.png' alt='' />
                    </div>
                  </div>
                  <div className='content-box'>
                    <h5 className='title'>
                      <Link to={'/solutions/elevator-intercom'}>
                        Elevator Intercom
                      </Link>
                    </h5>
                    <div className='text'>
                      Applied to elevator emergency or maintenance application.
                    </div>
                    <Link
                      to={'/solutions/elevator-intercom'}
                      className='read-more'
                    >
                      read More <i className='fa fa-long-arrow-alt-right'></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Services Section--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default SolutionsPage;
