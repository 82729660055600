import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
// import careerData from '../data/career_samples.json';
import careerData from '../data/career.json';

import Header from './components/Header';
import Footer from './components/Footer';

const CareerPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    setCareers(careerData);
  }, []);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | CAREER</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='about' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Career</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        <section className=''>
          <div className='container pb-0 pt-3'>
            <div className='row'>
              <div className='col'>
                <div className='section-title'>
                  <h4>Join Our Team</h4>
                  <div className='text'>
                    We are always looking for talented individuals to join our
                    team. If you are interested in working with us, please send
                    your resume to{'  '}
                    <a
                      href='mailto:enquiry@startechdistribution.com'
                      className='text-primary'
                    >
                      enquiry@startechdistribution.com
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- FAQ Section --> */}
        <section className=''>
          <div className='container pt-3'>
            <div className='row'>
              <div className='col'>
                <ul className='accordion-box wow fadeInRight'>
                  {careers.map((career, i) => (
                    <li className='accordion block' key={i}>
                      <div className='acc-btn'>
                        {i + 1}. {career.jobTitle}
                        <div className='icon fa fa-plus'></div>
                      </div>
                      <div className='acc-content'>
                        <div className='content'>
                          <div className='p-3 fs-5'>
                            Job Description:
                            <br />
                            <ul>
                              {career.jobDescriptions.map((desc, j) => (
                                <li key={j}>
                                  {'• '} {desc}
                                </li>
                              ))}
                            </ul>
                            <br />
                            Skills Required:
                            <br />
                            <ul>
                              {career.skills.map((skill, k) => (
                                <li key={k}>
                                  {'• '} {skill}
                                </li>
                              ))}
                            </ul>
                            <br />
                            <div className='d-flex justify-content-center w-100'>
                              <a
                                href='mailto:enquiry@startechdistribution.com'
                                className='theme-btn btn-style-one'
                              >
                                Send Us Your Resume
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {careers.length === 0 && (
                  <div className='col text-center'>
                    <h3>No careers available</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* <!--End FAQ Section --> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default CareerPage;
