import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

const ContactPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | CONTACT</title>
      </Helmet>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const name = encodeURIComponent(event.currentTarget.name.value);
    const email = encodeURIComponent(event.currentTarget.email.value);
    const website = encodeURIComponent(event.currentTarget.website.value);
    const message = encodeURIComponent(event.currentTarget.message.value);
    const subject = `Enquiry from ${name}`;

    const body = `Name: ${name}%0AEmail: ${email}%0AWebsite: ${website}%0A%0AMessage:%0A${message}%0A%0A`;

    window.location.href = `mailto:enquiry@startechdistribution.com?subject=${subject}&body=${body}`;
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Contact Us</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- Contact Section Three --> */}
        <section className='contact-section-three style-three'>
          <div className='auto-container'>
            <div className='row'>
              {/* <!-- Info Column --> */}
              <div className='content-column col-lg-6 col-md-12 col-sm-12 order-2'>
                <div className='inner-column wow fadeInRight'>
                  <div className='sec-title'>
                    <div className='sub-title'>CONTACT US</div>
                    <h2>Contact Us Let’s Talk Your Any Query.</h2>
                  </div>

                  <div className='info-box-outer'>
                    <div className='time-table-box'>
                      <div className='inner'>
                        <h4 className='title'>Opening Hour</h4>
                        <ul>
                          <li>
                            Mon - Fri{' '}
                            <span className='time-table'>9am - 6pm</span>
                          </li>
                          <li>
                            Saturday <span className='time-table'>Closed</span>
                          </li>
                          <li>
                            Sunday <span className='time-table'>Closed</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='contact-details-info'>
                      <div className='inner'>
                        <h3>Visit Our Location</h3>
                        <div className='contact-details-block'>
                          <div className='inner-box'>
                            <i className='icon fa fa-phone'></i>
                            <div className='title'>
                              Looking For Consultation
                            </div>
                            <div className='text fs-6'>
                              +603-7782 8826 <br />
                              +6012-6232077
                            </div>
                          </div>
                        </div>
                        <div className='contact-details-block'>
                          <div className='inner-box'>
                            <i className='icon fa fa-map-marker-alt'></i>
                            <div className='title'>Visit Our Location</div>
                            <div className='text fs-6'>
                              No. 31-2, Petaling Utama Avenue, Jalan PJS 1/50,
                              Taman Petaling Utama, 46150, Petaling Jaya ,
                              Selangor. Malaysia.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Form Column --> */}
              <div className='form-column col-lg-6 col-md-12 col-sm-12'>
                <div className='inner-column'>
                  {/* <!-- Contact Form --> */}
                  <div className='contact-form-two wow fadeInLeft'>
                    <div className='title-box'>
                      <h3>Have Any Questions</h3>
                      <span className='sub-title'>
                        Feel free to contact us through anywhere.
                      </span>
                    </div>
                    {/* <!--Contact Form--> */}
                    <form
                      id='contact-form'
                      action='mailto:enquiry@startechdistribution.com'
                      method='post'
                      enctype='text/plain'
                      autocomplete='off'
                      onSubmit={handleSubmit}
                    >
                      <div className='row gx-3'>
                        <div className='form-group col-lg-6 col-md-6 col-sm-12'>
                          <input
                            type='text'
                            name='name'
                            placeholder='Your Name'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-6 col-md-6 col-sm-12'>
                          <input
                            type='email'
                            name='email'
                            placeholder='Email Name'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <input
                            type='text'
                            name='website'
                            placeholder='Website'
                            required
                          />
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <textarea
                            name='message'
                            placeholder='Your Message'
                            required
                          ></textarea>
                        </div>

                        <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                          <button
                            className='theme-btn btn-style-one bg-dark'
                            type='submit'
                          >
                            <span className='btn-title'>GET SOLUTION</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <!--End Contact Form --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End Contact Section Three --> */}
        {/* <!-- Map Section--> */}
        <section className='map-section'>
          <iframe
            className='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.058974448886!2d101.646799575676!3d3.0789306535788405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba58cb0843f%3A0xaa3a41584d27ce1a!2sStar%20Tech%20Distribution%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1721122292081!5m2!1sen!2smy'
            width='600'
            height='450'
            style={{ border: '0' }}
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='Star Tech Distribution'
          ></iframe>
        </section>
        {/* <!--End Map Section--> */}

        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default ContactPage;
