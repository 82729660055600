import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SolutionsSelection from '../components/SolutionsSelection';

const HotelIntercomPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | SOLUTIONS | HOTEL INTERCOM</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='solutions' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Solutions</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}

        {/* <!--Start Services Details--> */}
        <section className='services-details'>
          <div className='container'>
            <div className='row'>
              <SolutionsSelection selection='hotel-intercom' />
              {/* <!--Start Services Details Content--> */}
              <div className='col-xl-8 col-lg-8'>
                <div className='services-details__content'>
                  <img src='../images/solutions-details/2.png' alt='' />
                  <h2 className='mt-4'>Service Overview</h2>
                  <div>
                    <div className='my-4'>
                      WevoPBX is an advanced telecommunication solution tailored
                      for the hospitality industry, enabling hotels to manage
                      internal and external communication effectively. It is
                      designed for ease of deployment and management through a
                      user-friendly web interface, making it ideal for hotel
                      staff.
                    </div>
                    <div className='my-4'>
                      <h5>1. Easy Deployment and Management</h5>
                      <p>
                        <strong>Overview:</strong> WevoPBX is designed for
                        seamless deployment in hotel environments, enabling easy
                        integration with existing communication infrastructure.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Web-Based Interface: The entire
                            system can be managed via a user-friendly web
                            interface, allowing hotel staff to configure
                            settings and manage calls without extensive
                            technical knowledge.
                          </li>
                          <li>
                            <strong>・</strong> Quick Setup: Simple installation
                            process that reduces downtime and allows for quick
                            implementation in hotel operations.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>2. Receptionist/Operator Functionality</h5>
                      <p>
                        <strong>Overview:</strong> The receptionist or operator
                        can efficiently manage incoming and outgoing calls
                        through a dedicated web operator panel.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Call Monitoring: Operators can
                            monitor live calls, ensuring high-quality service
                            and responsiveness to guest needs.
                          </li>
                          <li>
                            <strong>・</strong> Call Transfer: Easily transfer
                            calls between departments or to guest rooms,
                            enhancing communication efficiency within the hotel.
                          </li>
                          <li>
                            <strong>・</strong> Visual Call Management: The web
                            operator panel provides a visual representation of
                            ongoing calls, simplifying management tasks.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>3. User Portal for Guests</h5>
                      <p>
                        <strong>Overview:</strong> WevoPBX includes a web user
                        portal that allows hotel guests to manage their own
                        communication preferences.
                      </p>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Call Forwarding Settings: Guests
                            can set their own call forwarding options, ensuring
                            they never miss important calls during their stay.
                          </li>
                          <li>
                            <strong>・</strong> Web Fax Capabilities: Guests can
                            send and receive faxes directly through the web
                            interface, providing added convenience for business
                            travelers.
                          </li>
                          <li>
                            <strong>・</strong> Call Detail Records (CDR):
                            Guests can access their call logs and details,
                            allowing for easy tracking of communication during
                            their stay.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='my-4'>
                      <h5>4. Additional Features</h5>
                      <div>
                        <strong>Key Features:</strong>
                        <ul>
                          <li>
                            <strong>・</strong> Integration with Hotel
                            Management Systems: WevoPBX can integrate with
                            existing hotel management software, streamlining
                            operations and enhancing guest experiences.
                          </li>
                          <li>
                            <strong>・</strong> Voicemail and Messaging: Guests
                            can receive voicemail and messaging services,
                            ensuring they stay connected even when they’re not
                            in their rooms.
                          </li>
                          <li>
                            <strong>・</strong> Scalability: The system is
                            scalable, allowing hotels to easily add or remove
                            lines and features as their needs change.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h5>Conclusion</h5>
                    <p>
                      These telecommunication products collectively enhance
                      communication efficiency and collaboration in both
                      personal and professional settings. Organizations can
                      select the appropriate combination of these products based
                      on their specific communication needs, scalability, and
                      budget constraints.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--End Services Details Content--> */}
            </div>
          </div>
        </section>
        {/* <!--End Services Details--> */}
        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default HotelIntercomPage;
