import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Manuals from './pdfs/pdf_structure.json';

import Header from './components/Header';
import Footer from './components/Footer';

const TechnicalPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [openMainIndex, setOpenMainIndex] = useState(null);

  const toggleAccordion = (index, mainIndex) => {
    if (openIndex === index && openMainIndex === mainIndex) {
      // Close the currently open item
      setOpenIndex(null);
      setOpenMainIndex(null);
    } else {
      // Open the clicked item
      setOpenIndex(index);
      setOpenMainIndex(mainIndex);
    }
  };

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | TECHNICAL CENTER</title>
      </Helmet>
    );
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='technical' />
        {/* <!-- Start main-content --> */}
        <section
          className='page-title'
          style={{
            backgroundImage: 'url(../images/background/page-title.jpg)',
          }}
        >
          <div className='auto-container'>
            <div className='title-outer'>
              <h1 className='title'>Technical Center</h1>
            </div>
          </div>
        </section>
        {/* <!-- end main-content --> */}
        {/* <!-- FAQ Section --> */}
        <section className=''>
          <div className='container'>
            <div className='row'>
              {Manuals.map((brandData, index) => (
                <div className='col-12' key={index}>
                  <h3>{brandData.brand}</h3>
                  <ul className='accordion-box wow fadeInRight'>
                    {brandData.categories.length > 0
                      ? brandData.categories.map((category, catIndex) => (
                          <li
                            className={`accordion block ${
                              openIndex === catIndex && openMainIndex === index
                                ? 'active-block'
                                : ''
                            }`}
                            key={catIndex}
                          >
                            <div
                              className={`acc-btn ${
                                openIndex === catIndex &&
                                openMainIndex === index
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => toggleAccordion(catIndex, index)}
                            >
                              {category.category}
                              <div className='icon fa fa-plus'></div>
                            </div>
                            <div
                              className={`p-3 acc-content ${
                                openIndex === catIndex &&
                                openMainIndex === index
                                  ? 'current'
                                  : ''
                              }`}
                              style={{
                                display: `${
                                  openIndex === catIndex &&
                                  openMainIndex === index
                                    ? ''
                                    : 'none'
                                }`,
                              }}
                            >
                              {category.pdfs.map((pdf, pdfIndex) => (
                                <>
                                  <a
                                    href={`./pdfs/${
                                      brandData.brand + '/' + pdf
                                    }`}
                                    download
                                    key={pdfIndex}
                                  >
                                    <u>{pdf}</u>
                                  </a>
                                  <br />
                                </>
                              ))}
                            </div>
                          </li>
                        ))
                      : brandData.direct.length > 0 && (
                          <li
                            className={`accordion block ${
                              openMainIndex === index ? 'active-block' : ''
                            }`}
                          >
                            <div
                              className={`acc-btn ${
                                openMainIndex === index ? 'active' : ''
                              }`}
                              onClick={() => toggleAccordion(null, index)}
                            >
                              Installation Guide and Manuals
                              <div className='icon fa fa-plus'></div>
                            </div>
                            <div
                              className={`p-3 acc-content ${
                                openMainIndex === index ? 'current' : ''
                              }`}
                              style={{
                                display: `${
                                  openMainIndex === index ? '' : 'none'
                                }`,
                              }}
                            >
                              {brandData.direct.map((pdf, pdfIndex) => (
                                <>
                                  <a
                                    href={`./pdfs/${
                                      brandData.brand + '/' + pdf
                                    }`}
                                    download
                                    key={pdfIndex}
                                  >
                                    <u>{pdf}</u>
                                  </a>
                                  <br />
                                </>
                              ))}
                            </div>
                          </li>
                        )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <!--End FAQ Section --> */}

        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default TechnicalPage;
