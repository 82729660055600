// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import HomePage from './pages/HomePage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NewsPage from './pages/NewsPage';
import NewsDetailsPage from './pages/NewsDetailsPage';
import PortfolioPage from './pages/PortfolioPage';
import ProductsPage from './pages/ProductsPage';
import SolutionsPage from './pages/SolutionsPage';
import TechnicalPage from './pages/TechnicalPage';
import ContactPage from './pages/ContactPage';
import CareerPage from './pages/CareerPage';
import ElevatorIntercomPage from './pages/solutions/ElevatorIntercomPage';
import HotelIntercomPage from './pages/solutions/HotelIntercomPage';
import OfficeIntercomPage from './pages/solutions/OfficeIntercomPage';
import PagaSystemPage from './pages/solutions/PagaSystemPage';
import ResidentialIntercomPage from './pages/solutions/ResidentialIntercomPage';
import TelecommunicationProductsPage from './pages/solutions/TelecommunicationProductsPage';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Add more routes as needed */}
        <Route path='/' element={<HomePage />} exact />
        <Route path='/about' element={<AboutPage />} exact />
        <Route path="/news/:id" element={<NewsDetailsPage />} />
        <Route path='/news' element={<NewsPage />} exact />
        {/* <Route path='/news-details' element={<NewsDetailsPage />} exact /> */}
        <Route path='/portfolio' element={<PortfolioPage />} exact />
        <Route path='/products' element={<ProductsPage />} exact />
        <Route path='/solutions' element={<SolutionsPage />} exact />
        <Route path='/technical-center' element={<TechnicalPage />} exact />
        <Route path='/contact' element={<ContactPage />} exact />
        <Route path='/career' element={<CareerPage />} exact />
        <Route
          path='/solutions/elevator-intercom'
          element={<ElevatorIntercomPage />}
          exact
        />
        <Route
          path='/solutions/hotel-intercom'
          element={<HotelIntercomPage />}
          exact
        />
        <Route
          path='/solutions/office-intercom'
          element={<OfficeIntercomPage />}
          exact
        />
        <Route
          path='/solutions/paga-system'
          element={<PagaSystemPage />}
          exact
        />
        <Route
          path='/solutions/residential-intercom'
          element={<ResidentialIntercomPage />}
          exact
        />
        <Route
          path='/solutions/telecommunication-products'
          element={<TelecommunicationProductsPage />}
          exact
        />
      </Routes>
    </Router>
  );
};

export default App;
