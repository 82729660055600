import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import newsData from '../data/news.json';

import Header from './components/Header';
import Footer from './components/Footer';

const NewsDetailsPage = () => {
  const hasLoadedScripts = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const Head = () => {
    return (
      <Helmet>
        <title>STAR TECH | NEWS & EVENTS</title>
      </Helmet>
    );
  };

  const { id } = useParams()
  console.log(id)
  const newsItem = newsData.find(item => item.id === parseInt(id));

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      // Add other script paths here...
      '/js/jquery.js',
      '/js/popper.min.js',
      '/js/bootstrap.min.js',
      '/js/jquery.fancybox.js',
      '/js/jquery-ui.js',
      '/js/wow.js',
      '/js/appear.js',
      '/js/select2.min.js',
      '/js/swiper.min.js',
      '/js/owl.js',
      '/js/script.js',
    ];

    const loadScripts = async () => {
      if (!hasLoadedScripts.current) {
        try {
          for (const script of scripts) {
            await loadScript(script);
          }
          hasLoadedScripts.current = true; // Mark as loaded
          console.log('All scripts loaded successfully');
        } catch (error) {
          console.error('Error loading scripts', error);
        }
      }
    };

    loadScripts();
    setTimeout(() => {
      setIsLoaded(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1500);
  }, []);

  return (
    <>
      <Head />
      <div className='page-wrapper'>
        {/* <!-- Preloader --> */}
        {!isLoaded && <div className='preloader'></div>}
        <Header selection='news' />
        {/* <!-- Start main-content --> */}

        {/* <!--Blog Details Start--> */}
        <section className='blog-details'>
          <div className='auto-container '>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='blog-details__left'>
                  <div className='blog-details__content'>
                    <ul className='list-unstyled blog-details__meta'>
                      <li>
                        <a href='news-details.html'>
                          <i className='fas fa-user-circle'></i> Admin
                        </a>
                      </li>
                      <li>
                        <a href='news-details.html'>Publish Date: {newsItem.day} {newsItem.month} {newsItem.year}</a>
                      </li>
                    </ul>
                    <h3 className='blog-details__title'>
                      {newsItem.title}
                    </h3>
                    <div className='blog-details__img'>
                      <img src='../images/resource/news-details.jpg' alt='' />
                      <div className='blog-details__date'>
                        <span className='day'>{newsItem.day}</span>
                        <span className='month'>{newsItem.month}</span>
                      </div>
                    </div>
                    <p className='blog-details__text-2'>
                     {newsItem.content}
                    </p>
                    {/* <p className='blog-details__text-2'>
                      Mauris non dignissim purus, ac commodo diam. Donec sit
                      amet lacinia nulla. Aliquam quis purus in justo pulvinar
                      tempor. Aliquam tellus nulla, sollicitudin at euismod nec,
                      feugiat at nisi. Quisque vitae odio nec lacus interdum
                      tempus. Phasellus a rhoncus erat. Vivamus vel eros vitae
                      est aliquet pellentesque vitae et nunc. Sed vitae leo
                      vitae nisl pellentesque semper.
                    </p>
                    <p className='blog-details__text-2'>
                      Mauris non dignissim purus, ac commodo diam. Donec sit
                      amet lacinia nulla. Aliquam quis purus in justo pulvinar
                      tempor. Aliquam tellus nulla, sollicitudin at euismod nec,
                      feugiat at nisi. Quisque vitae odio nec lacus interdum
                      tempus. Phasellus a rhoncus erat. Vivamus vel eros vitae
                      est aliquet pellentesque vitae et nunc. Sed vitae leo
                      vitae nisl pellentesque semper.
                    </p> */}
                  </div>
                  <div className='blog-details__bottom'>
                    <div className='blog-details__social-list'>
                      {' '}
                      <a href='news-details.html'>
                        <i className='fa fa-link'></i>
                      </a>{' '}
                      <a href='news-details.html'>
                        <i className='fab fa-facebook'></i>
                      </a>{' '}
                      <a href='news-details.html'>
                        <i className='fab fa-whatsapp'></i>
                      </a>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--Blog Details End--> */}

        <Footer />
      </div>
      {/* <!-- Scroll To Top --> */}
      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
};

export default NewsDetailsPage;
